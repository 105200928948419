export const portfolioData = {
    data: [
        {
            id: 12,
            name: "오레오 x 블랙핑크",
            text: "글로벌 넘어원 쿠키 오레오 x 세계1위 K팝 아티스트 블랙핑크 이벤트 페이지",
            client: "(주)아이디어베이스",
            services: "PC, 모바일웹페이지, 모션, 퍼블리싱, 개발",
            years: "2023",
            category: "WEB, ADV",
            url: "https://oreoblackpink.kr",
            imgUrl: "/assets/images/portfolio/oreo.jpg",
            content: `<div class='text-center'><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/oreo01.jpg'
                    alt=''
                    class='img-con img-fluid'
                /><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/oreo02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },
        {
            id: 11,
            name: "MONTMONT",
            text: "광고대행사 오리콤 + 디지털 광고 플래이디 합작회사",
            client: "MONTMONT",
            services: "PC, 모바일웹페이지, 모션, 퍼블리싱, 개발",
            years: "2023",
            category: "WEB, ADV",
            url: "https://montmont.co.kr",
            imgUrl: "/assets/images/portfolio/montmont.jpg",
            content: `<div class='text-center'><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/montmont01.jpg'
                    alt=''
                    class='img-con img-fluid'
                /><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/montmont02.jpg'
                    alt=''
                    class='img-con img-fluid'
                /><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/montmont03.jpg'
                    alt=''
                    class='img-con img-fluid'
                /></div>`,
        },
        {
            id: 10,
            name: "NH농협카드 zgm.",
            text: "농협 zgm. 카드, 이벤트 페이지 제작.",
            client: "NH농협카드",
            services: "모바일페이지, 퍼블리싱, 모션",
            years: "2022, 2023",
            category: "WEB, ADV",
            url: "https://card.nonghyup.com/content/html/bridge/zgm",
            imgUrl: "/assets/images/portfolio/nh-zgm.jpg",
            content: `<div class='text-center'><div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/nh-zgm01.jpg'
                    alt=''
                    class='img-con img-fluid'
                /></div>`,
        },
        {
            id: 9,
            name: "신한투자증권",
            text: "2022년 신한투자증권 사명변경 이벤트 사이트 제작. 숨은그림찾기, 룰렛, 사용자 정보 저장, 카카오 로그인 연동",
            client: "신한투자증권",
            services: "웹페이지, 모바일페이지, 게임제작, 디비구축",
            years: "2022",
            category: "WEB, ADV",
            url: "https://digitalshinhansec.com",
            imgUrl: "/assets/images/portfolio/shinhan.jpg",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/shinhan01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },
        {
            id: 8,
            name: "유니클로",
            text: "2021, 2022년 연간 카드뉴스 및 웹진 제작 수주. 월 100건 카드뉴스 컨텐츠 및 월 10종 웹진 제작업무 진행중.",
            client: "유니클로",
            services: "카드뉴스, 컨텐츠, 웹진, 퍼블리싱",
            years: "2021, 2022",
            category: "WEB, ADV",
            url: "https://store-kr.uniqlo.com",
            imgUrl: "/assets/images/portfolio/uniq.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/uniq02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                <img
                    src='/assets/images/portfolio/uniq01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },
        {
            id: 7,
            name: "마이담스",
            text: "2022년 웹, 모바일 디자인 및 퍼블리싱 수주. 마이담스는 후순위 담보대출 한도 및 금리를 안내해드리는 플랫폼입니다.",
            client: "마이담스",
            services: "웹, 모바일, 퍼블리싱",
            years: "2022",
            category: "WEB, UI",
            url: "http://103.244.110.182",
            imgUrl: "/assets/images/portfolio/maidams.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/maidams02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                <img
                    src='/assets/images/portfolio/maidams01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },
        {
            id: 6,
            name: "교동식품 쇼핑몰",
            text: "2021년 교동식품 쇼핑몰 구축",
            client: "교동식품",
            services: "웹, 모바일, 퍼블리싱, 개발",
            years: "2021",
            url: "https://www.gdfoodmall.kr",
            category: "WEB, UI",
            imgUrl: "/assets/images/portfolio/gyodong.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/gyodong02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                <img
                    src='/assets/images/portfolio/gyodong01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },

        {
            id: 5,
            name: "더비비 성형외과",
            text: "더비비성형외과 반응형 웹사이트 제작 구축",
            client: "더비비 성형외과",
            services: "웹, 모바일, 퍼블리싱, 개발",
            years: "2021",
            category: "WEB, UI",
            url: "https://www.thebbprs.com",
            imgUrl: "/assets/images/portfolio/thebebe.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/thebebe02.png'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                <img
                    src='/assets/images/portfolio/thebebe01.png'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },

        {
            id: 4,
            name: "마루뱅크",
            text: "환전, 송금 사이트 마루뱅크, 웹, 앱, 리액트, 퍼블리싱 진행",
            client: "마루뱅크",
            services: "웹, 모바일, UI, 리액트, 퍼블리싱",
            years: "2020",
            url: "https://www.marubank.co.kr",
            category: "WEB, UI",
            imgUrl: "/assets/images/portfolio/marubank.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/marubank02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class=' mt40'></div>
                <img
                    src='/assets/images/portfolio/marubank02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },

        {
            id: 3,
            name: "용인보평서희스타힐스",
            text: "서희건설 용인보평 분양사이트 제작 및 광고진행",
            client: "서희스타힐스",
            services: "웹, 모바일, 퍼블리싱, 개발",
            years: "2020",
            url: "http://xn--on3b29ev7aa613bdpuk5e4milja.kr",
            imgUrl: "/assets/images/portfolio/stahills.png",
            content: `<div class=' mt40'></div>
                    <img
                    src='/assets/images/portfolio/stahills01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },

        {
            id: 2,
            name: "덕캔두잇",
            text: "덕캔두잇 반응형 웹사이트 제작. 모금 및 기타 굿즈판매 쇼핑플랫폼 구축.",
            client: "덕캔두잇",
            services: "웹, 모바일, 퍼블리싱, 개발",
            years: "2020",
            url: "",
            imgUrl: "/assets/images/portfolio/duckcan.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/duckcan01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/duckcan02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },

        {
            id: 1,
            name: "마스터카드",
            text: "마스터카드의 MC2020 프로젝트. 랜딩페이지",
            client: "마스터카드",
            services: "웹, 모바일, 퍼블리싱",
            years: "2020",
            url: "",
            imgUrl: "/assets/images/portfolio/citi.png",
            content: `<div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/citi01.jpg'
                    alt=''
                    class='img-con img-fluid'
                />
                <div class='mt40'></div>
                    <img
                    src='/assets/images/portfolio/citi02.jpg'
                    alt=''
                    class='img-con img-fluid'
                />`,
        },
    ],
};

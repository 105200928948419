import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux"; // 디스패치
import { useInput } from "./useInput";
import * as actions from "../../actions"; // store 엑션 함수를 호출
import { postMail } from "../../api/requestApi"; //메일 보내기 API 호출

const Request = () => {
    const [loading, setLoading] = useState(false);
    const [gPrivacy, handlePrivacyChange, onResetPrivacy] = useInput("");
    const [gName, handleNameChange, onResetName] = useInput("");
    const [gCompany, handleCompanyChange, onResetCompany] = useInput("");
    const [gEmail, handleEmailChange, onResetEmail] = useInput("");
    const [gPhone, handlePhoneChange, onResetPhome] = useInput("");
    const [gMessage, handleMessageChange, onResetMessage] = useInput("");
    const [data, setData] = useState(null);

    const privacyRef = useRef(null);
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const messageRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.setMain(false));
    });

    useEffect(() => {
        if (data !== null) {
            const { return_code, return_text } = data;

            if (return_code === "0000") {
                setLoading(false);
                alert(return_text);
                onResetPrivacy();
                onResetName();
                onResetCompany();
                onResetEmail();
                onResetPhome();
                onResetMessage();
            }
        }
    }, [
        data,
        onResetPrivacy,
        onResetName,
        onResetCompany,
        onResetEmail,
        onResetPhome,
        onResetMessage,
    ]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        console.log("loading", loading);

        try {
            if (gPrivacy === "") {
                alert("개인정보 활용 동의를 해주세요.");
                privacyRef.current.focus();
                setLoading(false);
                return false;
            }
            if (gName === "") {
                alert("이름을 입력 해주세요.");
                nameRef.current.focus();
                setLoading(false);
                return false;
            }
            if (gEmail === "") {
                alert("이메일을 입력 해주세요.");
                emailRef.current.focus();
                setLoading(false);
                return false;
            }
            if (gPhone === "") {
                alert("연락처를 입력 해주세요.");
                phoneRef.current.focus();
                setLoading(false);
                return false;
            }
            if (gMessage === "") {
                alert("내용을 입력 해주세요.");
                messageRef.current.focus();
                setLoading(false);
                return false;
            }

            console.log("submit ");

            const data = {
                gusername: gName,
                gemail: gEmail,
                gphone: gPhone,
                gsubject: "[더그레이프] 홈페이지발송 문의가 접수되었습니다.",
                gcomment: gMessage,
                gprivate: gPrivacy,
            };

            setData(await postMail(data));

            console.log("loading", loading);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="container-fluid mt150">
            <div className="container ">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-col-12 col-sm-12 col-12 left_gnb">
                            <div className="row">
                                <div className="col-12 fs_18 lh18 fc0 f_we5 letter36">
                                    PROJECT REQUEST
                                    <img
                                        src={require("../../assets/images/page/title_icon.png")}
                                        className="mtm5"
                                        alt=""
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 col-sm-12 col-12 mt40 mb50">
                                    <div className="col-12 fs_18 fc0 lh30 letter18 f_we6 ">
                                        문의사항을 메일로 남겨주세요. <br />
                                        최고의 전문가들이 당신을 기다리고
                                        있습니다.
                                    </div>

                                    <div className=" col-xl-12 col-lg-12 col-sm-12 col-12 fs_18 fc0 lh30 f_we2 mt50">
                                        더그레이프는 고객님들의 의견 접수와
                                        관련하여 성실한 답변을 드리기 위해
                                        최소한의 개인정보를 수집하고 있습니다.
                                    </div>

                                    <div className="col-12 fs_18 fc0 letter18 f_we7 ck_type1 mt50">
                                        <input
                                            ref={privacyRef}
                                            type="checkbox"
                                            id="ck1"
                                            name="g_privacy"
                                            value="1"
                                            onChange={handlePrivacyChange}
                                        />
                                        <label htmlFor="ck1">&nbsp;</label>
                                        <label htmlFor="ck1">
                                            {" "}
                                            개인정보 수집/이용 동의
                                        </label>
                                    </div>

                                    <div className="col-12 fs_18 fc0 lh30 f_we2 mt20">
                                        * 수집 및 이용 목적 : 고객 문의에 대한
                                        답변/안내
                                        <br />
                                        * 수집 및 이용 항목 : 이름, 이메일,
                                        연락처 등
                                        <br /> * 보유 및 이용기간 : 수집 및 이용
                                        목적 달성 시 까지
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="offset-xl-4 col-xl-8 offset-lg-4 col-lg-8 col-md-12 col-sm-12 col-12 ">
                            <div className="row">
                                <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                    기본정보
                                    <div className="dis_box title_icon" />
                                </div>

                                <div className="col-xl-12 col-lg-12 col-12 mt40">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                            <input
                                                ref={nameRef}
                                                type="text"
                                                name="g_name"
                                                className="form-control he54 fs_18 fc0 mb20"
                                                placeholder="이름"
                                                value={gName}
                                                onChange={handleNameChange}
                                            />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  ">
                                            <input
                                                type="text"
                                                name="g_company"
                                                className="form-control he54 fs_18 fc0 mb20"
                                                placeholder="기관 혹은 기업"
                                                value={gCompany}
                                                onChange={handleCompanyChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                            <input
                                                ref={emailRef}
                                                type="email"
                                                name="g_email"
                                                className="form-control he54 fs_18 fc0 mb20"
                                                placeholder="이메일"
                                                value={gEmail}
                                                onChange={handleEmailChange}
                                            />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  ">
                                            <input
                                                ref={phoneRef}
                                                type="tel"
                                                name="g_phone"
                                                className="form-control he54 fs_18 fc0 mb20"
                                                placeholder="전화번호"
                                                value={gPhone}
                                                onChange={handlePhoneChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="offset-xl-4 col-xl-8 offset-lg-4 col-lg-8 col-md-12 col-sm-12 col-12 ">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7 mt50">
                                문의내용
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  mt40">
                                <textarea
                                    ref={messageRef}
                                    name="g_message"
                                    className="form-control fs_18"
                                    rows="7"
                                    placeholder="How may I help you?"
                                    value={gMessage}
                                    onChange={handleMessageChange}
                                />
                            </div>

                            <div className="col-12  mt40">
                                {!loading ? (
                                    <input
                                        type="submit"
                                        name="g_submit"
                                        className="submit_bt1"
                                        value="문의하기"
                                    />
                                ) : (
                                    <input
                                        type="submit"
                                        name="g_submit"
                                        className="submit_bt1"
                                        value="데이터 입력중"
                                        disabled
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Request;

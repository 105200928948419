import axios from "axios";

const gitav_url = "";

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

export const postMail = async (getData) => {
    const data = JSON.stringify(getData);
    console.log(data);
    const result = await axios
        .post(`${gitav_url}/main/getEmail`, data, config)
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
    return result;
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux"; // 디스패치
import * as actions from "../../actions"; // store 엑션 함수를 호출
import { CgLink } from "react-icons/cg";
import SEOMetaTag from "../../api/SEOMetaTag";

const Andproject = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.setMain(false));
    });
    return (
        <>
            <SEOMetaTag
                title={`더그레이프 - 이외의 포트폴리오`}
                description={`그레이프, 포트폴리오, 원익머트리얼즈, 충북대학교, 지식재산센터, 사회과학대학, 인아글로벌 방문자시스템, 신규거래 시스템, 퍼블리싱, 개발, 웹프로그램`}
                keywords={`더그레이프, 포트폴리오, 원익머트리얼즈, 충북대학교, 지식재산센터, 사회과학대학, 인아글로벌 방문자시스템, 신규거래 시스템, 퍼블리싱, 개발, 웹프로그램`}
            />
            <div className="container-fluid mt150">
                <div className="container">
                    <div className="col-12 fs_18 lh18 fc0 f_we5 letter36">
                        AND PORTFOLIO
                        <img
                            src={require("../../assets/images/page/title_icon.png")}
                            className="mtm5"
                            alt=""
                        />
                    </div>
                    <div className="row mt47">
                        <div className="col-lg-6 col-md-6 col-12 fs_18 fc0 lh35 mt25 justify-content-end">
                            년도별로 정리한 <strong>AND PORTFOLIO</strong>
                        </div>

                        <div className="col-lg-6 col-md-6 col-12 text-end fc00 mt25 fs_18 f_we5 letter18">
                            <Link to="/portfolio">PORTFOLIO</Link>
                        </div>
                    </div>

                    <div className="row mt80">
                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb100">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                2022
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  fc6 fs_18 lh35 f_we2 mt25">
                                <a
                                    href={`https://vpass.wimco.co.kr`}
                                    target="_blank"
                                    title={`(주)원익머티리얼즈 방문자시스템 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)원익머티리얼즈 '방문자시스템'
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://opensrc.wimco.co.kr`}
                                    target="_blank"
                                    title={`(주)원익머티리얼즈 신규거래시스템 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)원익머티리얼즈 '신규거래시스템'
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`http://referee.gitav.kr`}
                                    target="_blank"
                                    title={`충북지역인적자원개발위원회 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북지역인적자원개발위원회
                                    '온라인심사시스템'
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://rounded.co.kr`}
                                    target="_blank"
                                    title={`(주)라운디드 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)라운디드 'ROUNDED' 쇼핑몰
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://webzine.tipa.or.kr`}
                                    target="_blank"
                                    title={`중소기업기술정보진흥원 웹진 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    중소기업기술정보진흥원 'TIPA웹진'
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                오리온 '리츠크레커' 이벤트 페이지
                                <br />
                                충북대학교병원 '임종임박위험 확률계산' 어플 개발
                                <br />
                                GS칼텍스 '에너지플러스EV' 이벤트 페이지
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb100">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                2021
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  fc6 fs_18 lh35 f_we2 mt25">
                                <a
                                    href={`http://cip.cbnu.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 지식재산전문인력양성센터 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '지식재산전문인력양성센터' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://ipclinic.cbnu.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 지식재산클리닉 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '지식재산클리닉' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://inamall.co.kr`}
                                    target="_blank"
                                    title={`인아몰 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)인아글로벌 '인아몰' 쇼핑몰
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                씨티은행 랜딩 및 퍼블리싱 년간 진행
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb100">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                2020
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  fc6 fs_18 lh35 f_we2 mt25">
                                <a
                                    href={`https://sscollege.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 사회과학대학 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '사회과학대학' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://sociology.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 사회학과 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '사회학과' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://psychology.chungbuk.ac.kr/`}
                                    target="_blank"
                                    title={`충북대학교 심리학과 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '심리학과' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://public.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 행정학과 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '행정학과' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://econ.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 경제학과 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '경제학과' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://politics.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 정치외교학과 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '정치외교학과' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://sopa.chungbuk.ac.kr`}
                                    target="_blank"
                                    title={`충북대학교 세종국가정책대학원 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북대학교 '세종국가정책대학원' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`http://issuepress.kr/r`}
                                    target="_blank"
                                    title={`ISSUEPRESS 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    계명대학교 국제학연구소 'ISSUEPRESS' 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb100">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                2019
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  fc6 fs_18 lh35 f_we2 mt25">
                                <a
                                    href={`https://wimco.co.kr`}
                                    target="_blank"
                                    title={`(주)원익머티리얼즈 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)원익머티리얼즈 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://sus304mall.com`}
                                    target="_blank"
                                    title={`신한MS 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)신한엠에스 '신한MS' 쇼핑몰
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://openthecurtain.com`}
                                    target="_blank"
                                    title={`오픈더커튼 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)거스타이드 '오픈더커튼' 쇼핑몰
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-sm-6 col-12 mb100">
                            <div className="col-12 position-relative fs_18 fc0 lh18 letter36 f_we7">
                                2018
                                <div className="dis_box title_icon" />
                            </div>
                            <div className="col-12  fc6 fs_18 lh35 f_we2 mt25">
                                <a
                                    href={`https://cjrhrdc.org`}
                                    target="_blank"
                                    title={`충북지역인적자원개발위원회 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    충북지역인적자원개발위원회 사이트
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`https://www.huiln.com`}
                                    target="_blank"
                                    title={`휴일엔 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    (주)메모리얼 '휴일엔' 쇼핑몰
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                                <br />
                                <a
                                    href={`http://thekoeco.com`}
                                    target="_blank"
                                    title={`KOECO 바로가기`}
                                    rel="noopener noreferrer"
                                >
                                    코이코 'KOECO'
                                    <CgLink className="pb3 pl5 fs_18" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Andproject;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // 디스패치
import * as actions from "../../actions"; // store 엑션 함수를 호출
import MainContainer from "./MainContainer";
import styled from "styled-components";
import SEOMetaTag from "../../api/SEOMetaTag";

const Container = styled.div`
    position: relative;
    display: flex;
    height: 100vh;
    height: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    z-index: 0;
    overflow-y: hidden;
`;

const Home = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.setMain(true));
    });
    return (
        <>
            <SEOMetaTag
                title={`더그레이프`}
                description={`그레이프, 포트폴리오, 원익머트리얼즈, 충북대학교, 지식재산센터, 사회과학대학, 인아글로벌 방문자시스템, 신규거래 시스템, 퍼블리싱, 개발, 웹프로그램`}
                keywords={`더그레이프, 포트폴리오, 원익머트리얼즈, 충북대학교, 지식재산센터, 사회과학대학, 인아글로벌 방문자시스템, 신규거래 시스템, 퍼블리싱, 개발, 웹프로그램`}
            />
            <Container className="container-fluid">
                <MainContainer />
            </Container>
        </>
    );
};

export default Home;

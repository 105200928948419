/**
 * 셋팅 체크
 */
export const SET_GNB = "SET_GNB"; // 메인 메뉴 true or false
export const SET_MAIN = "SET_MAIN"; // 메인 true, 서브 false
export const SET_VISIBLE_GNB = "SET_VISIBLE_GNB"; // 얜 왜?
export const POST_VIEW_TOGGLE = "POST_VIEW_TOGGLE"; // 얘도 왜?
export const SET_PAGE_LOADING = "SET_PAGE_LOADING"; // 페이지 로딩

/**  두개 이용 안함 추후 변경*/
export const CURRENT_PAGE = "CUEERNT_PAGE";
export const PREVENT_PAGE = "PREVENT_PAGE";

import * as types from "../actions/ActionTypes";

const initialState = {
    isMain: true,
    isGnbVisible: false,
    isPostViewToggle: false,
    isPageLoad: false,
    NaviItems: [
        { id: 1, name: "HOME", url: "/" },
        { id: 2, name: "WE ARE", url: "/weare" },
        { id: 3, name: "PORTFOLIO", url: "/portfolio" },
        { id: 4, name: "CONTACT US", url: "/contactus" },
    ],
};

export default function ui(state = initialState, action) {
    switch (action.type) {
        case types.SET_GNB:
            return {
                ...state,
                NaviItems: action.NaviItems,
            };
        case types.SET_VISIBLE_GNB:
            return {
                ...state,
                isGnbVisible: action.isGnbVisible,
            };
        case types.SET_MAIN:
            return {
                ...state,
                isMain: action.isMain,
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                isPageLoad: action.isPageLoad,
            };
        case types.POST_VIEW_TOGGLE:
            return {
                ...state,
                isPostViewToggle: action.isPostViewToggle,
            };
        /**  두개 이용 안함 추후 변경*/
        case types.CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage,
            };
        case types.PREVENT_PAGE:
            return {
                ...state,
                preventPage: action.preventPage,
            };
        default:
            return state;
    }
}

import React from "react";
import List from "./List";
import View from "./View";

export const Portfolio = () => {
    return <List />;
};

export const PortfolioView = () => {
    return <View />;
};

import React from "react";
import { hydrate, render } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux"; // 류듀스 제공
import store from "./reducers"; // 스토어를 조절
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = document.getElementById("root");

/**
 * react-helmet-async
 * https://velog.io/@miyoni/noSSRyesSEO
 */
if (root.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <Provider store={store}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </Provider>
        </React.StrictMode>,
        root
    );
} else {
    render(
        <React.StrictMode>
            <Provider store={store}>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </Provider>
        </React.StrictMode>,
        root
    );
}
/*

import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security
*/

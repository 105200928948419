import React, { useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux"; // 디스패치
import styled from "styled-components";

import { HambergerMenu } from "./HambergerMenu";
import { gsap, Power3 } from "gsap";

/**
 * 참고 페이지
 * https://codesandbox.io/s/react-gsap-animation-toite?from-embed=&file=/src/components/Menu.js:671-679
 */

const GNBContainer = styled.div`
    z-index: 100;
    display: flex;
    width: 100%;
    height: 6rem;
`;

const tl = gsap.timeline();

const Header = (props) => {
    const divStyle = {
        opacity: 0,
    };

    const divRef = useRef();
    const isMain = useSelector((state) => state.ui.isMain);
    useLayoutEffect(() => {
        const div01 = divRef.current;
        tl.to(div01, {
            opacity: 1,
            delay: 2.0,
            duration: 0.3,
            ease: Power3.easeInOut,
        });
    }, [isMain]);

    return (
        <>
            <GNBContainer
                ref={divRef}
                style={divStyle}
                className={`container-fluid fixed-top ${
                    !isMain ? "subBg" : ""
                }`}
            >
                <HambergerMenu />
            </GNBContainer>
        </>
    );
};

export default Header;

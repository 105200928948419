import { useState } from "react";

export function useInput(init) {
    const [inputValue, setInputValue] = useState(init);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    const onReset = () => setInputValue(init);

    return [inputValue, handleChange, onReset];
}
